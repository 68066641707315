import MotionRightItem from "./elements/motion/motionRightItem";

const PageHeader = (props) => {
  const { image, title, description } = props;
  return (
    <header
      className={`w-full relative bg-center bg-cover before:w-full before:h-full before:z-[1] before:absolute before:top-1 before:left-0 before:right-0 before:-bottom-1 before:bg-gradient-to-b before:from-black/0 before:from-50% before:to-[#191919] before:to-100% select-none`}
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <div className="overlay pt-48 pb-32">
        <div className="flex flex-col mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw]">
          <div className="wrapper w-full z-[2]">
            {props.children ? (
              <>
                <MotionRightItem className="flex text-4xl justify-center text-center md:text-6xl md:justify-start font-bold w-full">
                  {title}
                </MotionRightItem>
                <MotionRightItem className="mt-5 flex justify-center text-center md:justify-start text-md">
                  {props.children}
                </MotionRightItem>
              </>
            ) : (
              <>
                <MotionRightItem
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.5 }}
                  className="flex text-4xl justify-center text-center md:text-6xl md:justify-start font-bold w-full"
                >
                  {title}
                </MotionRightItem>
                <MotionRightItem
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.5 }}
                  className="mt-5 flex justify-center text-center md:justify-start text-md"
                >
                  {description}
                </MotionRightItem>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default PageHeader;

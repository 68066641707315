import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ScrollMemory = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollPositions, setScrollPositions] = useState({});

  useEffect(() => {
    if (window.innerWidth < 768) { return; }
    const handleScroll = () => {
      if (navigate) {
        setScrollPositions((prevScrollPositions) => ({
          ...prevScrollPositions,
          [location.pathname]: window.scrollY,
        }));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (window.innerWidth < 768) { return; }
    if (location.pathname in scrollPositions) {
      window.scrollTo(0, scrollPositions[location.pathname]);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, scrollPositions]);

  return children;
};

export default ScrollMemory;

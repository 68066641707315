import { motion } from "framer-motion";

const motionUpItem = {
  offscreen: {
    y: 40,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const MotionUpItem = (props) => {
  return (
    <motion.div
      variants={motionUpItem}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.5 }}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
};

export default MotionUpItem;
import SevenDaysTwoDieLogo from "../assets/images/game_images/7D2D.webp";
import ARKSALogo from "../assets/images/game_images/Ark_SA.webp";
import ARKSELogo from "../assets/images/game_images/Ark_SE.webp";
import AstroneerLogo from "../assets/images/game_images/Astroneer.webp";
import CSGOLogo from "../assets/images/game_images/CSGO.webp";
import FiveMLogo from "../assets/images/game_images/FiveM.webp";
import GmodLogo from "../assets/images/game_images/Gmod.webp";
import InsurgencySandstormLogo from "../assets/images/game_images/Insurgency_Sandstorm.webp";
import L4D2Logo from "../assets/images/game_images/Left4Dead2.webp";
import MinecraftLogo from "../assets/images/game_images/Minecraft.webp";
import ProjectZomboidLogo from "../assets/images/game_images/PZ.webp";
import PalworldLogo from "../assets/images/game_images/Palworld.webp";
import RustLogo from "../assets/images/game_images/Rust.webp";
import SonsOfTheForestLogo from "../assets/images/game_images/SOTF.webp";
import SatisfactoryLogo from "../assets/images/game_images/Satisfactory.webp";
import SquadLogo from "../assets/images/game_images/Squad.webp";
import TerrariaLogo from "../assets/images/game_images/Terraria.webp";
import UnturnedLogo from "../assets/images/game_images/Unturned.webp";
import GamesBanner from "../assets/images/gamesbanner.webp";
import MotionUpItem from "../components/elements/motion/motionUpItem";
import MotionLeftItem from "../components/elements/motion/motionLeftItem";
import GameListContainer from "../components/gamelistcontainer";
import PageHeader from "../components/header";
import StandardFeaturesContainers from "../components/standardFeatureContainer";
import { useCurrency } from "../utils/providers/useCurrency";
import { TypeAnimation } from "react-type-animation";
import { useServerDetails } from "../utils/providers/useServerDetails";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  const { currency } = useCurrency();
  const { serverDetails } = useServerDetails();

  const gameServers = [
    {
      name: "Minecraft",
      image: MinecraftLogo,
      price: 0.79,
      link: "minecraft",
    },
    {
      name: "Palworld",
      image: PalworldLogo,
      link: "palworld",
    },
    {
      name: "Rust",
      image: RustLogo,
      link: "rust",
    },
    {
      name: "Ark: Survival Evolved",
      image: ARKSELogo,
      link: "ark-survival-evolved",
    },
    {
      name: "Garry's Mod",
      image: GmodLogo,
      link: "garrys-mod",
    },
    {
      name: "Sons of the Forest",
      image: SonsOfTheForestLogo,
      link: "sons-of-the-forest",
    },
    {
      name: "Ark: Survival Ascended",
      image: ARKSALogo,
      price: null,
      link: "ark-survival-ascended",
    },
    {
      name: "Five M",
      image: FiveMLogo,
      price: null,
      link: "five-m",
    },
    {
      name: "CSGO 2",
      image: CSGOLogo,
      price: null,
      link: "csgo",
    },
    {
      name: "Terraria",
      image: TerrariaLogo,
      price: null,
      link: "terraria",
    },
    {
      name: "Squad",
      image: SquadLogo,
      price: null,
      link: "squad",
    },
    {
      name: "Insurgency: Sandstorm",
      image: InsurgencySandstormLogo,
      price: null,
      link: "insurgency-sandstorm",
    },
    {
      name: "Unturned",
      image: UnturnedLogo,
      price: null,
      link: "unturned",
    },
    {
      name: "Project Zomboid",
      image: ProjectZomboidLogo,
      price: null,
      link: "project-zomboid",
    },
    {
      name: "Satisfactory",
      image: SatisfactoryLogo,
      price: null,
      link: "satisfactory",
    },
    {
      name: "Left 4 Dead 2",
      image: L4D2Logo,
      price: null,
      link: "left-4-dead-2",
    },
    {
      name: "7 Days To Die",
      image: SevenDaysTwoDieLogo,
      price: null,
      link: "7-days-to-die",
    },
    {
      name: "Astroneer",
      image: AstroneerLogo,
      price: null,
      link: "astroneer",
    },
  ];

  const standardFeatures = [
    {
      name: "DDoS Protection",
      icon: "fas fa-shield-alt",
    },
    {
      name: "Full Secure FTP Access",
      icon: "fas fa-lock",
    },
    {
      name: "Instant Provisioning",
      icon: "fas fa-download",
    },
    {
      name: "Unlimited Slots",
      icon: "fas fa-infinity",
    },
    {
      name: "Free Support",
      icon: "fas fa-headset",
    },
    {
      name: "Free MySQL Database",
      icon: "fas fa-database",
    },
    {
      name: "Add Sub-Users",
      icon: "fas fa-user-plus",
    },
    {
      name: "Resource Monitoring",
      icon: "fas fa-chart-line",
    },
    {
      name: "Scheduled Tasks",
      icon: "fas fa-tasks",
    },
    {
      name: "Free Back-ups",
      icon: "fas fa-cloud-download-alt",
    },
    {
      name: "NVMe SSD Storage",
      icon: "fas fa-hdd",
    },
    {
      name: "24 Hour Money Back Guarantee",
      icon: "fas fa-money-check-alt",
    },
    {
      name: "In Panel File Editor",
      icon: "fas fa-file-code",
    },
    {
      name: "European Hosting",
      icon: "fas fa-globe-europe",
    },
    {
      name: "99.9% Uptime",
      icon: "fas fa-clock",
    },
    {
      name: "Configurable Options",
      icon: "fas fa-cogs",
    },
    {
      name: "Custom Game Panel",
      icon: "fas fa-tachometer-alt",
    },
    {
      name: "Documented Setup Guides",
      icon: "fas fa-book",
    },
  ];

  return (
    <>
    <Helmet>
        <title>Synify Hosting</title>
        <meta
          name="description"
          content="High quality server hosting at an affordable price."
        />
      </Helmet>
      <PageHeader
        image={GamesBanner}
        title="Game Server Hosting"
        backgroundColor="#191919"
      >
        <TypeAnimation
          sequence={[
            "High quality server hosting at an affordable price.",
            3000,
            `From Minecraft to Rust, we have you covered.`,
            3000,
          ]}
          wrapper="span"
          cursor={false}
          repeat={Infinity}
          className="inline-block h-4"
        />
      </PageHeader>
      <section className="bg-[#191919]">
        <div className="flex flex-col items-center justify-center mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] py-20 bg-transparent">
          <MotionUpItem className="text-4xl font-bold text-center">
            Our Game Servers
          </MotionUpItem>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mt-8">
            {/* Game server */}
            {gameServers.map((gameServer) => {
              return (
                <MotionLeftItem key={gameServer.name}>
                  <GameListContainer
                    key={gameServer.name}
                    gameServer={gameServer}
                    serverDetails={serverDetails.find(
                      (game) => game.url === gameServer.link
                    )}
                    currency={currency}
                  />
                </MotionLeftItem>
              );
            })}
          </div>
          {currency !== "GBP" && (
            <MotionUpItem className="mt-16 text-neutral-400">
              *Prices may not be accurate. Price conversions from are updated
              daily based on GBP.
            </MotionUpItem>
          )}
        </div>
      </section>
      <section>
        <div className="flex flex-col items-center justify-center mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] py-20 bg-transparent">
          <MotionUpItem className="text-4xl font-bold text-center">
            We Offer All You Need To Manage Your Servers With Our Game Panel
          </MotionUpItem>
          <MotionUpItem className="text-xl text-center text-neutral-300">
            The following comes as standard with all game servers.
          </MotionUpItem>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mt-8">
            {standardFeatures.map((feature) => (
              <MotionLeftItem key={feature.name}>
                <StandardFeaturesContainers
                  key={feature.name}
                  feature={feature}
                />
              </MotionLeftItem>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;

import PageHeader from "../../../components/header";
import { useCurrency } from "../../../utils/providers/useCurrency";
import ConfigurePlan from "./configurePlan";
import SelectPlan from "./selectPlan";
import minecraftbg from "../../../assets/images/minecraftbg.jpg";
import { motion } from "framer-motion";

const motionUpItem = {
  offscreen: {
    y: 40,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const MinecraftPage = () => {
  const { currency, calculatePrice, currencySymbol } = useCurrency();
  return (
    <>
      <PageHeader
        title="Minecraft Servers"
        description={`Starting from ${currencySymbol[currency]}${calculatePrice(
          0.75
        )} per extra GB of RAM it's one of the lowest prices you'll find!`}
        image={minecraftbg}
        backgroundColor="#191919"
      />
      <section className="bg-[#191919]">
        <div className="flex flex-col items-center justify-center mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] py-20 bg-transparent">
          <motion.div
            variants={motionUpItem}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.5 }}
            className="text-4xl font-bold text-center"
          >
            Our Minecraft Hosting Plans
          </motion.div>
          <SelectPlan />
        </div>
      </section>
      <section className="bg-[#212121]">
        <div className="flex flex-col items-center justify-center mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] py-20 bg-transparent">
          <motion.div
            variants={motionUpItem}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.5 }}
            className="text-4xl font-bold text-center"
          >
            Configure Your Server
          </motion.div>
          <motion.div
            variants={motionUpItem}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.5 }}
            className="text-xl text-center text-neutral-300 pb-16"
          >
            Choose your plan and configure your server to your needs.
          </motion.div>
          <ConfigurePlan />
        </div>
      </section>
    </>
  );
};

export default MinecraftPage;

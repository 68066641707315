const StandardFeaturesContainers = (props) => {
    const { feature } = props;
  return (
    <div
      key={feature.name}
      className="flex flex-row items-center justify-start gap-2 text-neutral-300 bg-[#191919] px-4 py-2 rounded-lg shadow-lg"
    >
      <i className={`${feature.icon} text-2xl`}></i>
      <span className="w-full text-center">{feature.name}</span>
    </div>
  );
};

export default StandardFeaturesContainers;

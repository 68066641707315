import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/elements/button";
import { useCurrency } from "../../../utils/providers/useCurrency";
import { useEffect, useState } from "react";
import PopularContainer from "../../../components/elements/popular";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

const motionLeftItem = {
  offscreen: {
    x: 40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const SelectPlan = () => {
  const [planType, setPlanType] = useState("");
  const [searchParams] = useSearchParams();
  const params = useParams();
  useEffect(() => {
    const plan = searchParams.get("plan");
    setPlanType(plan ? plan : "budget");
  }, [params, searchParams, setPlanType]);
  const { currency, calculatePrice, currencySymbol } = useCurrency();
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>
          Synify Hosting | Minecraft{" "}
          {planType === "premium"
            ? "| Premium Plan"
            : planType === "extreme"
            ? "| Extreme Plan"
            : ""}
        </title>
        <meta
          name="description"
          content="Minecraft servers starting from £0.75 per extra GB of RAM. One of the lowest prices you'll find!"
        />
      </Helmet>
      <div className="flex flex-col md:flex-row gap-8 mt-16 w-full">
        <motion.div
          variants={motionLeftItem}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
          className="flex flex-col w-full md:w-1/3 bg-[#313131] rounded-3xl"
        >
          <div className="p-12 bg-[#212121] rounded-t-3xl">
            <div className="text-3xl font-bold mb-4">Starter</div>
            <div className="text-sm text-neutral-400">
              A great option for those running servers with friends or on a
              tight budget - starting at {currencySymbol[currency]}
              {calculatePrice(0.79)} and an extra {currencySymbol[currency]}
              {calculatePrice(0.75)}/GB, the Budget server hosting plan is
              perfect for those who want to start a small server.
            </div>
          </div>
          <div className="p-6 h-full flex flex-col justify-between">
            <ul className="flex flex-col gap-2 mt-4">
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-microchip w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Intel Xeon E5-1630v3 @ 3.7Ghz
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-memory w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDR4 2133MHz ECC Memory
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-infinity w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Unlimited Slots
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-shield w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDoS Protection
              </li>
            </ul>
            <div className="mt-4">
              <Button
                type="primary"
                disabled={planType === "budget" ? true : false}
                onClick={() => navigate(".?plan=budget")}
              >
                Configure Plan
              </Button>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={motionLeftItem}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
          className="flex flex-col w-full md:w-1/3 bg-[#313131] rounded-3xl relative"
        >
          <PopularContainer className="absolute top-4 right-4">
            Most Popular
          </PopularContainer>
          <div className="p-12 bg-[#212121] rounded-t-3xl">
            <div className="text-3xl font-bold mb-4">Premium</div>
            <div className="text-sm text-neutral-400">
              Premium is perfect for those who are running a larger server, or
              want more power for their server. Starting at{" "}
              {currencySymbol[currency]}
              {calculatePrice(1.49)}.
            </div>
          </div>
          <div className="p-6 h-full flex flex-col justify-between">
            <ul className="flex flex-col gap-2 mt-4">
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-microchip w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                {
                  "Ryzen 7 3800X @4.5GHz - (Ryzen 7 Pro 8700GE @5.1GHz - Coming Soon)"
                }
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-memory w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDR4 2666MHz ECC Memory - (DDR5 3600MHz ECC Memory - Coming Soon)
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-hdd w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                NVMe SSDs
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-infinity w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Unlimited Slots
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-shield w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDoS Protection
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-flag w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Custom Startup Flags
              </li>
            </ul>
            <div className="mt-4">
              <Button
                type="primary"
                disabled={planType === "premium" ? true : false}
                onClick={() => navigate(".?plan=premium")}
              >
                Configure Plan
              </Button>
            </div>
          </div>
        </motion.div>
        <motion.div
          variants={motionLeftItem}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.5 }}
          className="flex flex-col w-full md:w-1/3 bg-[#313131] rounded-3xl"
        >
          <div className="p-12 bg-[#212121] rounded-t-3xl">
            <div className="text-3xl font-bold mb-4">Extreme</div>
            <div className="text-sm text-neutral-400">
              For those who want the best of the best, the Extreme plan is
              perfect for you. With the best hardware, unlimited slots and
              dedicated resources, you can't go wrong.
            </div>
          </div>
          <div className="p-6 h-full flex flex-col justify-between">
            <ul className="flex flex-col gap-2 mt-4 text-justify">
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-microchip w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Ryzen 9 7950X3D @5.7GHz
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-memory w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDR5 3600MHz ECC Memory
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-hdd w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                NVMe SSDs
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-server w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Dedicated vCPUs
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-infinity w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Unlimited Slots
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-shield w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                DDoS Protection
              </li>
              <li className="text-start flex flex-row items-center">
                <i
                  className={`fas fa-flag w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                ></i>{" "}
                Custom Startup Flags
              </li>
            </ul>
            <div className="mt-4">
              <Button
                type="primary"
                disabled={planType === "extreme" ? true : false}
                onClick={() => navigate(".?plan=extreme")}
              >
                Configure Plan
              </Button>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default SelectPlan;

import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/elements/button";
import { useCurrency } from "../../../utils/providers/useCurrency";
import { motion } from "framer-motion";
import CountUp from "react-countup";

const motionUpItem = {
  offscreen: {
    y: 40,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const ConfigurePlan = () => {
  const [planType, setPlanType] = useState("");
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [ramSlider, setRamSlider] = useState(4);
  const [threadSlider, setThreadSlider] = useState(0);
  const [ram, setRam] = useState(5);
  const [threads, setThreads] = useState(2);
  const [extraThreads, setExtraThreads] = useState(0);
  const [totalThreads, setTotalThreads] = useState();
  const [price, setPrice] = useState(4.99);
  const { currency, calculatePrice, currencySymbol } = useCurrency();

  useEffect(() => {
    setTotalThreads(threads + extraThreads);
  }, [threads, extraThreads]);

  useEffect(() => {
    const plan = searchParams.get("plan");
    setPlanType(plan ? plan : "budget");
    setRam(plan === "extreme" ? 6 : 5);
    setThreads(plan === "extreme" ? 3 : 2);
    setThreadSlider(0);
    setExtraThreads(0);
    setRamSlider(plan === "extreme" ? 0 : 4);
  }, [params, searchParams, setPlanType]);

  useEffect(() => {
    const handlePrice = (ram, extraThreads) => {
      if (planType === "extreme") {
        setPrice(24.99 + (ram - 6) * 1.75 + extraThreads * 10.0);
      } else if (planType === "premium") {
        setPrice(1.49 + (ram - 1) * 1.5 + extraThreads * 3.5);
      } else {
        setPrice(0.79 + (ram - 1) * 0.75);
      }
    };

    handlePrice(ram, extraThreads);
  }, [ram, totalThreads, planType]);

  const handleRam = (e) => {
    const value = e.target.value;

    if (planType === "extreme") {
      switch (value) {
        case "0":
          setRam(6);
          break;
        case "1":
          setRam(8);
          break;
        case "2":
          setRam(10);
          break;
        case "3":
          setRam(12);
          break;
        case "4":
          setRam(16);
          break;
        case "5":
          setRam(20);
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case "0":
          setRam(1);
          setThreads(1);
          break;
        case "1":
          setRam(2);
          setThreads(1);
          break;
        case "2":
          setRam(3);
          setThreads(1);
          break;
        case "3":
          setRam(4);
          setThreads(2);
          break;
        case "4":
          setRam(5);
          setThreads(2);
          break;
        case "5":
          setRam(6);
          setThreads(2);
          break;
        case "6":
          setRam(7);
          setThreads(3);
          break;
        case "7":
          setRam(8);
          setThreads(3);
          break;
        case "8":
          setRam(10);
          setThreads(4);
          break;
        case "9":
          setRam(12);
          setThreads(4);
          break;
        case "10":
          setRam(16);
          setThreads(5);
          break;
        default:
          break;
      }
    }
  };

  const handleThreads = (e) => {
    const value = e.target.value;
    if (planType === "extreme") {
      switch (value) {
        case "0":
          setExtraThreads(0);
          break;
        case "1":
          setExtraThreads(1);
          break;
        case "2":
          setExtraThreads(2);
          break;
        default:
          break;
      }
    } else {
      switch (value) {
        case "0":
          setExtraThreads(0);
          break;
        case "1":
          setExtraThreads(1);
          break;
        case "2":
          setExtraThreads(2);
          break;
        case "3":
          setExtraThreads(3);
          break;
        case "4":
          setExtraThreads(4);
          break;
        case "5":
          setExtraThreads(5);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="w-full flex flex-col">
      <motion.div
        variants={motionUpItem}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.5 }}
        className="w-full bg-[#191919] rounded-3xl"
      >
        <div className="px-8 pt-8 text-2xl font-bold">
          {ram}GB{" "}
          {planType === "extreme"
            ? "Extreme"
            : planType === "premium"
            ? "Premium"
            : "Budget"}{" "}
          Minecraft
          {planType === "budget"
            ? null
            : ` - ${totalThreads} ${
                planType === "extreme" ? "Dedicated" : ""
              } vCPU${totalThreads > 1 ? "s" : ""}`}
        </div>
        <div className="w-full px-4 md:px-32 pt-16 flex flex-col items-center">
          <div className="w-full flex flex-col items-center relative">
            <input
              type="range"
              name=""
              id=""
              min="0"
              max={planType === "extreme" ? "5" : "10"}
              value={ramSlider}
              onInput={(e) => {
                handleRam(e);
                setRamSlider(e.target.value);
              }}
              className="!w-[94%] md:!w-[98%] !mr-1 z-10"
            />
          </div>
          <div className="w-full pt-2">
            <div className="flex justify-between pb-10">
              {planType === "extreme" ? null : (
                <>
                  <div className="w-12 relative text-center text-md md:text-lg">
                    1GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 hidden md:block relative text-center md:text-lg">
                    2GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center text-md md:text-lg">
                    3GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 hidden md:block relative text-center md:text-lg">
                    4GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center text-md md:text-lg">
                    5GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                </>
              )}
              {planType === "extreme" ? (
                <>
                  <div className="w-12 relative text-center md:text-lg">
                    6GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center md:text-lg">
                    8GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-12  hidden md:block relative text-center md:text-lg">
                    6GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center text-md md:text-lg">
                    7GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 hidden md:block relative text-center md:text-lg">
                    8GB
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                </>
              )}
              <div className="w-12 relative text-center text-md md:text-lg">
                10GB
                <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
              </div>
              {planType === "extreme" ? (
                <div className="w-12 relative text-center md:text-lg">
                  12GB
                  <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                </div>
              ) : (
                <div className="w-12 hidden md:block relative text-center md:text-lg">
                  12GB
                  <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                </div>
              )}
              <div className="w-12 relative text-center text-md md:text-lg">
                16GB
                <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
              </div>
              {planType === "extreme" ? (
                <div className="w-12 relative text-center text-md md:text-lg">
                  20GB
                  <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                </div>
              ) : null}
            </div>
          </div>
          {planType === "budget" ? null : (
            <>
              <div className="w-full flex flex-col items-center relative">
                <input
                  type="range"
                  name=""
                  id=""
                  min="0"
                  max={planType === "extreme" ? "2" : "5"}
                  value={threadSlider}
                  onInput={(e) => {
                    handleThreads(e);
                    setThreadSlider(e.target.value);
                  }}
                  className="!w-[94%] md:!w-[98%] !mr-1 z-10 "
                />
              </div>
              <div className="w-full pt-2">
                <div className="flex justify-between pb-10">
                  {planType === "extreme" ? null : (
                    <>
                      <div className="w-12 relative text-center text-md md:text-lg">
                        0
                        <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                      </div>
                      <div className="w-12 relative text-center md:text-lg">
                        1
                        <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                      </div>
                      <div className="w-12 relative text-center text-md md:text-lg">
                        2
                        <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                      </div>
                    </>
                  )}
                  <div className="w-12 relative text-center md:text-lg">
                    3
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center md:text-lg">
                    4
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                  <div className="w-12 relative text-center text-md md:text-lg">
                    5
                    <div className="absolute w-1 h-4 bg-[#313131] -top-4 right-6"></div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center py-8 px-16 bg-[#313131] rounded-b-3xl">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="text-3xl font-bold flex flex-row items-end">
              {currencySymbol[currency]}
              <CountUp
                decimals="2"
                end={calculatePrice(price)}
                duration={1.5}
                preserveValue={true}
                delay={0}
              />
              <div className="text-xl font-normal">/month</div>
            </div>
            <Button
              to={`https://billing.synify.uk/cart/minecraft?plan=${planType}?ram=${ram}${
                planType === "budget" ? "" : "?threads=" + totalThreads
              }`}
              type="primary"
              target="_blank"
            >
              Add to Cart
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ConfigurePlan;

import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  //Navigate,
} from "react-router-dom";
//import socketIO from "socket.io-client";
import Navbar from "./components/partials/navbar";
import Footer from "./components/partials/footer";
import Logo from "./assets/images/mountain-white-square.png";
import HomePage from "./pages/homePage";
import MinecraftPage from "./pages/games/minecraft/minecraftPage";
import MomentumScroll from "./components/smoothScroll";
import ScrollMemory from "./components/elements/scroll/ScrollMemory";
import OtherGamesPage from "./pages/games/other";

const helmetContext = {};

function App() {
  return (
    <div className="app">
      <HelmetProvider context={helmetContext}>
        <Router>
          {window.innerWidth > 767 && <ScrollMemory />}
            <Navbar />
            <MomentumScroll>
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route
                  exact
                  path="/games/minecraft"
                  element={<MinecraftPage />}
                />
                <Route
                  exact
                  path="/games/*"
                  element={< OtherGamesPage />}
                />
              </Routes>
              <Footer />
            </MomentumScroll>
        </Router>
      </HelmetProvider>
    </div>
  );
}

export default App;

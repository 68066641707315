import { useEffect, useState } from "react";
import PageHeader from "../../components/header";
import { useServerDetails } from "../../utils/providers/useServerDetails";
import Button from "../../components/elements/button";
import { useCurrency } from "../../utils/providers/useCurrency";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import PopularContainer from "../../components/elements/popular";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

const motionLeftItem = {
  offscreen: {
    x: 40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const OtherGamesPage = () => {
  const [selectedGame, setSelectedGame] = useState();
  const params = useParams();
  const pathname = window.location.pathname;
  const { serverDetails } = useServerDetails();
  const { calculatePrice, currency, currencySymbol } = useCurrency();

  useEffect(() => {
    const game = serverDetails.find(
      (game) => game.url === pathname.split("/")[2]
    );
    setSelectedGame(game);
  }, [pathname, serverDetails, params]);

  return (
    <>
      <Helmet>
        <title>Synify Hosting | {`${selectedGame?.title ? selectedGame?.title : "Unknown Game"}`}</title>
        <meta
          name="description"
          content="Get your own high quality minecraft server up and running instantly at such a low price. Starting at only £0.79/month."
        />
      </Helmet>
      <PageHeader
        title={selectedGame?.title ? selectedGame?.title : "Can't find the game your looking for"}
        description={selectedGame?.description}
        image={selectedGame?.image}
      />
      <section className="bg-[#191919]">
        <div className="flex flex-col items-center justify-center mx-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] py-20 bg-transparent">
          {selectedGame ? selectedGame?.servers ? (
            <div
              className={`grid ${
                selectedGame?.servers?.length === 1 ? "" : "md:grid-cols-2"
              } ${
                selectedGame?.servers?.length <= 3
                  ? "lg:grid-cols-" + selectedGame?.servers?.length
                  : "lg:grid-cols-4"
              }  gap-8 mt-16 w-full`}
            >
              {selectedGame?.servers.map((server, index) => {
                return (
                  <motion.div
                    variants={motionLeftItem}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.5 }}
                    key={index}
                    className="flex flex-col w-full bg-[#313131] rounded-3xl relative"
                  >
                    {server.popular && server.price && (
                      <PopularContainer className="absolute top-4 right-4">
                        Most Popular
                      </PopularContainer>
                    )}
                    <div className="p-12 bg-[#212121] rounded-t-3xl">
                      <div className="text-3xl font-bold mb-4">
                        {server.name}
                      </div>
                      <div className="text-sm text-neutral-400">
                        {server.description}
                      </div>
                    </div>
                    <div className="p-6 h-full flex flex-col justify-between">
                      <ul className="flex flex-col gap-2 mt-4 text-justify">
                        {server.features.map((feature, index) => {
                          return (
                            <li
                              key={index}
                              className="text-start flex flex-row items-center"
                            >
                              <i
                                className={`fas fa-${feature.icon} w-8 min-w-8 aspect-square text-xl text-center justify-center items-center my-auto mr-1 flex`}
                              ></i>
                              {feature.text}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="text-2xl font-bold flex flex-row items-end my-4 ">
                        {server.price ? (
                          <>
                            {currencySymbol[currency]}
                            <CountUp
                              decimals="2"
                              end={calculatePrice(server.price)}
                              duration={1.5}
                              preserveValue={true}
                              delay={0}
                            />
                            <div className="text-sm font-normal mb-[2px]">
                              /month
                            </div>
                          </>
                        ) : (
                          "Coming Soon"
                        )}
                      </div>
                      <Button
                        to={server.available ? server.link : null}
                        disabled={server.available ? null : true}
                        target="_blank"
                        type="primary"
                        className="btn btn-primary"
                      >
                        {server.available ? "Order Now" : "Unavailable"}
                      </Button>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          ) : (
            <div className="text-4xl font-bold text-center">Coming Soon</div>
          ) : ""}
        </div>
      </section>
    </>
  );
};

export default OtherGamesPage;

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import ChevronDownIcon from "../../assets/icons/chevronDownIcon";
import { useState } from "react";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavbarDropdown(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Menu as="div" className="dropdown relative inline-block text-left">
      <div>
        <Menu.Button
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 py-2 text-sm shadow-sm outline-none text-gray-300"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {props.title}
          <ChevronDownIcon
            className="h-5 w-5 -mr-1 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-[#212121] shadow-lg ring-1 ring-purple-500 ring-opacity-40 focus:outline-none">
          <div className="py-1 h-fit max-h-48 overflow-auto">
            {props?.options?.map((option) => {
              return (
                <Menu.Item key={option.title}>
                  {({ active }) => (
                    <Link
                      to={option.link}
                      className={classNames(
                        active ? "bg-[#252525] text-purple-200" : "text-white",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {option.title}
                    </Link>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

import { motion } from "framer-motion";

const motionRightItem = {
  offscreen: {
    x: -40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const MotionRightItem = (props) => {
  return (
    <motion.div
      variants={motionRightItem}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.5 }}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
}

export default MotionRightItem;
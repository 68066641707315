import { motion } from "framer-motion";

const motionLeftItem = {
  offscreen: {
    x: 40,
    opacity: 0,
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 0.9,
    },
  },
};

const MotionLeftItem = (props) => {
  return (
    <motion.div
      variants={motionLeftItem}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.25 }}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
}

export default MotionLeftItem;
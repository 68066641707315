import MainLogo from "../../assets/icons/logo.svg";
import PoweredByStripeIcon from "../../assets/icons/poweredByStripe";

const Footer = () => {
  return (
    <footer className="bg-[#191919] select-none">
      <div className="flex md:flex-row justify-between">
        <div className="flex flex-col items-center md:items-start md:flex-row pt-20 pb-12 m-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw]">
          <div className="flex flex-col w-full items-center md:items-start md:w-2/12">
            <div className="text-xl">Products</div>
            <ul className="text-sm text-neutral-400 flex flex-col gap-2 mt-4 mb-12">
            <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk/games/minecraft">Minecraft</a></li>
            <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk/games/ark-survival-evolved">ARK:SE</a></li>
            <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk/games/rust">Rust</a></li>
            <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk/games/palworld">Palworld</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk">All Game Servers</a></li>
            </ul>
          </div>
          <div className="flex flex-col w-full items-center md:items-start md:w-2/12">
            <div className="text-xl">Support</div>
            <ul className="text-sm text-neutral-400 flex flex-col gap-2 mt-4 mb-12">
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://docs.synify.uk">Knowledgebase</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Status Page</li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://discord.gg/JjgTdmhT9J">Discord</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Server Specs</li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Demo Panel</li>
            </ul>
          </div>
          <div className="flex flex-col w-full items-center md:items-start md:w-2/12">
            <div className="text-xl">Clients</div>
            <ul className="text-sm text-neutral-400 flex flex-col gap-2 mt-4 mb-12">
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://panel.synify.uk">Panel login</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://billing.synify.uk">Billing Login</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Support Tickets</li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://synify.uk/about-us">About us</a></li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start"><a href="https://uk.trustpilot.com/review/synify.uk">Rate Us</a></li>
            </ul>
          </div>
          <div className="flex flex-col w-full items-center md:items-start md:w-2/12">
            <div className="text-xl">Legal</div>
            <ul className="text-sm text-neutral-400 flex flex-col gap-2 mt-4 mb-12">
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Term of Service</li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Privacy Policy</li>
              <li className="hover:text-purple-500 transition-all duration-300 w-full cursor-pointer text-center text-lg md:text-sm md:w-fit md:text-start">Report Abuse</li>
            </ul>
          </div>
          <div className="flex flex-col md:w-4/12">
            <div className="text-sm text-neutral-400 flex flex-col gap-2 mt-4 mb-12 w-full items-center md:items-start">
              <div className="w-fit">
                <MainLogo
                  className={"h-8 sm:h-12 fill-current text-purple-600"}
                />
                <h1 className="w-full text-center font-bold font-[Quicksand] text-3xl">
                  Synify
                </h1>
              </div>
              <div>Registered in England and Wales</div>
              <div>Company No. 15509638</div>
              {/*<div>Copyright 2024 - {(new Date(Date.now()).getFullYear())} © Synify Limited</div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-black">
        <div className="flex flex-row justify-between">
          <div className="flex row py-4 m-auto w-[95vw] md:w-[90vw] lg:w-[80vw] xl:w-[75vw] justify-between">
            <div></div>
            <a href="https://stripe.com" target="_blank" rel="noreferrer" aria-label="Link to stripe.com"><PoweredByStripeIcon className="h-8 w-fit text-[#212121] fill-current bg-white/30 rounded-xl hover:bg-purple-500/50 transition-all duration-200" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { createContext, useContext, useState } from "react";

// Create a new context for the currency provider
const CurrencyContext = createContext();

// Create a custom hook to use the currency context
export const useCurrency = () => {
  return useContext(CurrencyContext);
};

const exchangeRates = await fetch("/api/currency-rates").then((response) =>
  response.json()
);
// Create a CurrencyProvider component
export const CurrencyProvider = ({ children }) => {
  // Define the state for the currency
  const currencyOptions = [
    "AUD",
    "CAD",
    "CHF",
    "EUR",
    "GBP",
    "HKD",
    "JPY",
    "NZD",
    "USD"
  ];
  const [currency, setCurrency] = useState("GBP");

  const currencySymbol = {
    AUD: "AU$",
    CAD: "CA$",
    CHF: "₣",
    EUR: "€",
    GBP: "£",
    HKD: "HK$",
    JPY: "¥",
    NZD: "NZ$",
    USD: "$",
  };

  const calculatePrice = (price) => {
    return (price * exchangeRates[0].currency[currency]).toFixed(2);
  };

  // Define any other state or functions related to currency

  // Provide the currency state and any related functions to the children components
  return (
    <CurrencyContext.Provider
      value={{ currency, setCurrency, calculatePrice, currencyOptions, currencySymbol }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

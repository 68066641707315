import { Link } from "react-router-dom";
import { useCurrency } from "../utils/providers/useCurrency";
import CountUp from 'react-countup';

const GameListContainer = (props) => {
  const { currencySymbol, calculatePrice } = useCurrency();
  const { currency, gameServer, serverDetails } = props;
  return (
    <Link
      key={gameServer.name}
      to={"games/" + gameServer.link}
      className="group flex flex-col items-center justify-center p-4 bg-black/50 rounded-lg shadow-md border-opacity-0 border-purple-600 border-2 hover:border-opacity-100 select-none"
      id={gameServer.link}
    >
      <div className="aspect-video w-full overflow-hidden flex flex-col relative rounded-md">
        <div
          className="flex items-end justify-center w-full h-full bg-black/50 bg-cover bg-center group-hover:scale-110 transition-all duration-300"
          style={{ backgroundImage: `url(${gameServer.image})` }}
        ></div>
        <div className="flex absolute bottom-0 w-full justify-center text-xl font-bold bg-black/70 py-1">
          {gameServer.name}
        </div>
      </div>
      <div className="text-neutral-400 mt-4">
        {gameServer.price || serverDetails?.servers ? (
          gameServer.price || serverDetails?.servers[0].price ? <>
            Starting at{" "}
            <span className="text-purple-500 font-bold">
              {currencySymbol[currency]}
              <CountUp decimals="2" end={calculatePrice(serverDetails?.servers[0]?.price ? serverDetails?.servers[0]?.price : gameServer.price)} duration={1.5} preserveValue={true} delay={0}/>
              {currency !== "GBP" && (
                <span
                  title="Prices may not be accurate. Prices are converted from GBP and are updated daily."
                  className="text-neutral-500"
                >
                  *
                </span>
              )}
            </span>
          </> : `Coming Soon!`
        ) : (
          `Coming Soon!`
        )}
      </div>
    </Link>
  );
};

export default GameListContainer;

import React, { createContext, useContext } from "react";

const ServerDetailsContext = createContext();

export const useServerDetails = () => {
  return useContext(ServerDetailsContext);
};

const serverDetails = await fetch("/api/game-server-details").then((response) =>
  response.json()
);

export const ServerDetailsProvider = ({ children }) => {

  return (
    <ServerDetailsContext.Provider
      value={{ serverDetails }}
    >
      {children}
    </ServerDetailsContext.Provider>
  );
};

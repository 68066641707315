import React, { useRef, useState, useCallback, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";
import {
  useScroll,
  useTransform,
  useSpring,
  motion,
} from "framer-motion";

const MomentumScroll = ({ children }) => {
  const scrollRef = useRef(null);

  const [scrollableHeight, setScrollableHeight] = useState(0);

  const resizeScrollableHeight = useCallback((entries) => {
    for (let entry of entries) {
      setScrollableHeight(entry.contentRect.height);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) =>
      resizeScrollableHeight(entries)
    );
    scrollRef.current && resizeObserver.observe(scrollRef.current);
    return () => resizeObserver.disconnect();
  }, [resizeScrollableHeight]);

  const { scrollY } = useScroll();

  const negativeScrollY = useTransform(
    scrollY,
    [0, scrollableHeight],
    [0, -scrollableHeight]
  );

  const springPhysics = {
    damping: 30,
    mass: 0.1,
    stiffness: 200,
    bounce: 0,
    duration: 0.1,
    velocity: 100,
  };

  const springNegativeScrollY = useSpring(negativeScrollY, springPhysics);

  if (window.innerWidth < 768) {
    return <div className="scroll-container">{children}</div>;
  } else {
    return (
      <>
        <motion.div
          ref={scrollRef}
          style={{ y: springNegativeScrollY }}
          className="scroll-container fixed top-0 left-0 w-full overflow-hidden will-change-transform min-h-screen"
        >
          {children}
        </motion.div>

        <div style={{ height: scrollableHeight }} />
      </>
    );
  }
};

export default MomentumScroll;

import { Link } from "react-router-dom";
import MainLogo from "../../assets/icons/logo.svg";
import NavbarDropdown from "../elements/navbarDropdown";
import Announcements from "../partials/announcements";
import { useState } from "react";
import NavbarCurrencyDropdown from "../elements/navbarCurrencyDropdown";
import { useEffect } from "react";

const Navbar = () => {
  const [announcement, setAnnouncement] = useState("");
  const [announcementType, setAnnouncementType] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  function handleMenu() {
    setMenuOpen(!menuOpen);
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const panelLoginOptions = [
    {
      title: "Game Panel",
      link: "https://panel.synify.uk",
    },
    {
      title: "Billing Panel",
      link: "https://billing.synify.uk",
    },
  ]

  const gameServerDropdownOptions = [
    {
      title: "Minecraft",
      link: "/games/minecraft",
    },
    {
      title: "Palworld",
      link: "/games/palworld",
    },
    {
      title: "Rust",
      link: "/games/rust",
    },
    {
      title: "Ark: Survival Evolved",
      link: "/games/ark-survival-evolved",
    },
    {
      title: "Garry's Mod",
      link: "/games/garrys-mod",
    },
    {
      title: "Sons of the Forest",
      link: "/games/sons-of-the-forest",
    },
    {
      title: "Ark: Survival Ascended",
      link: "/games/ark-survival-ascended",
    },
    {
      title: "Five M",
      link: "/games/five-m",
    },
    {
      title: "CSGO",
      link: "/games/csgo",
    },
    {
      title: "Terraria",
      link: "/games/terraria",
    },
    {
      title: "Squad",
      link: "/games/squad",
    },
    {
      title: "Insurgency: Sandstorm",
      link: "/games/insurgency-sandstorm",
    },
    {
      title: "Unturned",
      link: "/games/unturned",
    },
    {
      title: "Project Zomboid",
      link: "/games/project-zomboid",
    },
    {
      title: "Satisfactory",
      link: "/games/satisfactory",
    },
    {
      title: "Left 4 Dead 2",
      link: "/games/left-4-dead-2",
    },
    {
      title: "7 Days to Die",
      link: "/games/7-days-to-die",
    },
    {
      title: "Astroneer",
      link: "/games/astroneer",
    },
  ]

  return (
    <div className="z-30 w-full select-none fixed">
      {announcement && <Announcements />}
      <nav
        className={
          "navbar z-30 w-full h-20 px-[2vw] md:px-[5vw] lg:px-[10vw] xl:px-[12.5vw] flex flex-row justify-between relative " +
          (scrollPosition === 0
            ? "navbar top"
            : "backdrop-blur-sm navbar bottom")
        }
      >
        <Link to="/" className="z-50 flex justify-center items-center">
          <MainLogo className={"h-8 sm:h-12 fill-current text-purple-600"} />
          <h1 className="text-2xl font-bold ml-4 font-[Quicksand] sm:text-3xl">
            Synify
          </h1>
        </Link>
        <ul className="flex-row items-center hidden md:flex lg:gap-2 xl:gap-4">
          <li>
            <div className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 py-2 text-sm shadow-sm outline-none text-neutral-300">
              <Link to="/games/minecraft">Minecraft Hosting</Link>
            </div>
          </li>
          <li>
            <NavbarDropdown title="Game Servers" options={gameServerDropdownOptions }/>
          </li>
          <li>
            <NavbarDropdown title="Panel Login" options={panelLoginOptions}/>
          </li>
          <li>
            <NavbarCurrencyDropdown />
          </li>
        </ul>
        {/*<!-- mobile menu -->*/}
        <div className="z-50 flex md:hidden">
          <ul className="flex flex-row items-center gap-4">
            <li>
              <NavbarCurrencyDropdown />
            </li>
            <li>
              <button
                className={`text-white focus:outline-none ${
                  menuOpen ? "cross-animation" : ""
                }`}
                aria-label="Open Menu"
                onClick={handleMenu}
              >
                {menuOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </li>
          </ul>
        </div>
        <div
          className={`${
            menuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
          } md:hidden w-full h-screen bg-[#212121] fixed left-0 z-40 transition-opacity duration-300 ease-in-out`}
        >
          <ul className="flex flex-col items-center justify-center h-full">
            <li className="mb-4">
              <Link
                to="https://panel.synify.uk"
                className="text-white"
                onClick={handleMenu}
              >
                Game Panel
              </Link>
            </li>
            <li className="mb-4">
              <Link
                to="https://billing.synify.uk"
                className="text-white"
                onClick={handleMenu}
              >
                Billing Panel
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

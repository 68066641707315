import { Link } from "react-router-dom";

const Button = (props) => {
    const { type } = props;

    const button = {
        primary: "bg-purple-600 hover:bg-purple-800 text-white",
        secondary: "bg-[#414141] border-2 border-[#515151] hover:bg-[#313131] text-neutral-200",
        danger: "bg-danger-500 hover:bg-danger-600 text-white",
        submit: "bg-purple-600 hover:bg-purple-800 text-white",
    };

    if (props.to) {
        return ( 
            <Link
                className={`py-2 px-4 rounded-lg transition-all duration-200 text-center flex justify-center items-center h-12 select-none ${button[type]} ${props.className ? props.className : ""} ${props.disabled ? "cursor-not-allowed opacity-50" : "hover:shadow-md"}`}
                onClick={props.onClick}
                disabled={props.disabled}
                to={props.to}
                target={props.target ? props.target : "_self"}
            >
                {props.children}
            </Link>
         );
    } else {
        return ( 
            <button
                className={`py-2 px-4 rounded-lg transition-all duration-200 text-center flex justify-center items-center h-12 select-none ${button[type]} ${props.className ? props.className : ""} ${props.disabled ? "cursor-not-allowed opacity-50" : "hover:shadow-md"}`}
                onClick={props.onClick}
                disabled={props.disabled}
            >
                {props.children}
            </button>
         );
    }
}
 
export default Button;